var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Outlet from "./components/Outlet.vue";
const ONE_MINUTE = 60;
const INTERVAL = 500;
const ONE_SECOND = 1000;
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.phone = null;
        this.remain = ONE_MINUTE;
    }
    get title() {
        if (!this.data)
            return "";
        return this.data.Promotion.CodeName == "eventgiveaway" ? "發送次數" : "發送集點";
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.data = yield post("/api/Reception/GetRecord", { Id: this.id });
                setTimeout(() => this.countdown(), INTERVAL);
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message);
                this.$router.back();
            }
        });
    }
    countdown() {
        const now = new Date().getTime();
        this.remain = ONE_MINUTE - Math.ceil((now - this.data.CreateTime.getTime()) / ONE_SECOND);
        if (this.remain <= 0)
            this.$router.back();
        else
            setTimeout(() => this.countdown(), INTERVAL);
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            const result = yield post("/api/Reception/Send", { Id: this.id, Name: this.phone });
            const next = yield this.confirm(`
					<div class="row">
						<div class="col">會員：</div>
						<div class="col text-right">
							${result.UserSerial}<br>${this.phone}
						</div>
					</div>
					<hr>
					<div class="p-2">${this.data.Promotion.Name}</div>
					<div class="row">
						<div class="col">${this.data.Item.Name}：</div>
						<div class="col text-right">${result.Count} ${this.data.Item.Unit}</div>
					</div>
					<div class="row">
						<div class="col">發送門市：</div>
						<div class="col text-right">${result.Outlet}</div>
					</div>
				`, result.ReceiveTime.minute() + "<br>兌換成功", "success", "繼續發送", "關閉");
            if (next)
                this.$router.back();
            else
                this.$router.push("/reception");
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { Outlet } })
], Page);
export default Page;
