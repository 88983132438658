var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let InfoList = class InfoList extends Vue {
    constructor() {
        super(...arguments);
        this.startedOpen = false;
        this.notStartedOpen = false;
    }
    get started() {
        return this.data.Tickets.filter(t => t.Started);
    }
    get notStarted() {
        return this.data.Tickets.filter(t => !t.Started);
    }
    sum(records) {
        return records.reduce((a, b) => a + b.Amount, 0);
    }
};
__decorate([
    Prop()
], InfoList.prototype, "data", void 0);
InfoList = __decorate([
    Component
], InfoList);
export default InfoList;
