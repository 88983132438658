
	import { Component, Prop, Vue } from "vue-property-decorator";

	import { hasTime } from "@common/date";

	@Component
	export default class StartEnd extends Vue {
		@Prop() public data!: {
			StartTime: Date;
			EndTime: Date;
		};
		@Prop(Boolean) public noMin?: boolean;
		@Prop(Boolean) public showRemain?: boolean;


		protected get hasTime(): boolean {
			if(this.noMin) return false;
			return hasTime(this.data.StartTime, this.data.EndTime);
		}

		protected get start(): string {
			return this.hasTime ? this.data.StartTime.minute() : this.data.StartTime.date();
		}

		protected get end(): string {
			if(!this.data.EndTime) return "至今";
			return this.hasTime ? this.data.EndTime.minute() : this.data.EndTime.date();
		}

		protected remain(date: Date): number {
			const diff = Math.max(0, date.getTime() - new Date().getTime());
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			return Math.ceil(diff / 86400000);
		}
	}
