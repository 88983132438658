var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import { mark } from "@common/string";
let Eye = class Eye extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
    }
    get display() {
        if (this.show)
            return this.text;
        return mark(this.text, this.symbol, this.head, this.tail);
    }
};
__decorate([
    Prop()
], Eye.prototype, "text", void 0);
__decorate([
    Prop()
], Eye.prototype, "symbol", void 0);
__decorate([
    Prop()
], Eye.prototype, "head", void 0);
__decorate([
    Prop()
], Eye.prototype, "tail", void 0);
Eye = __decorate([
    Component
], Eye);
export default Eye;
