import { render, staticRenderFns } from "./PrepareSend.vue?vue&type=template&id=7cd2c806&scoped=true&"
import script from "./PrepareSend.vue?vue&type=script&lang=ts&"
export * from "./PrepareSend.vue?vue&type=script&lang=ts&"
import style0 from "./PrepareSend.vue?vue&type=style&index=0&id=7cd2c806&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd2c806",
  null
  
)

export default component.exports