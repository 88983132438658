var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Eye from "./components/Eye.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            this.data = yield post("/api/Reception/GetCardInfo", { Id: this.id });
            if (this.data == null) {
                // 找不到卡片
                this.alert("此卡片並未綁定任何會員", undefined, "error");
                this.$router.back();
            }
        });
    }
    unbind() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            try {
                if (!(yield this.confirm("確定解除卡片的會員綁定？此操作無法復原！", "解除綁定", "warning")))
                    return;
                yield post("/api/Reception/Unbind", { Id: this.data.Id });
                yield this.load(); // 此步驟會觸發卡片重新建立使用者
            }
            catch (e) {
                if (e instanceof Error) {
                    yield this.alert(e.message, "解除綁定失敗", "error");
                }
            }
        });
    }
    lookup() {
        this.$router.push("/reception/lookup/" + this.data.UserId);
    }
    next() {
        this.$router.push("/reception/scan/" + this.id);
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Inject()
], Page.prototype, "confirm", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { Eye } })
], Page);
export default Page;
