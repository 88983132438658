var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { EventGiveawayType } from "@/models/Owner";
import TicketHeader from "../info/components/TicketHeader.vue";
import RangeDisplay from "../owner/components/RangeDisplay.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.showPhone = false;
        this.showSerial = false;
    }
    mounted() {
        this.load();
    }
    get showRange() {
        if (!this.data)
            return false;
        const settings = this.data.Promotion.Settings;
        if (settings.Type == EventGiveawayType.Ticket)
            return false;
        return settings.MinActivationCount != settings.MaxActivationCount;
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = yield post("/api/Reception/TicketInfo", { Id: this.ticketId, Name: this.code });
            if (this.data == null) {
                yield this.alert("票券編號無效", undefined, "warning");
                this.$router.back();
            }
            else if (this.data.ReceiveTime && this.data.Promotion.Settings.Type == EventGiveawayType.Link) {
                yield this.alert("已達領取上限", undefined, "warning");
                this.$router.back();
            }
        });
    }
    save() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            try {
                const amount = yield post("/api/Reception/Save/", { Id: this.ticketId, Name: this.id });
                yield this.alert(`${amount} ${this.data.Unit}`, `恭喜獲得${this.data.ItemName}`, "success");
                this.$router.push("/reception/nfc");
            }
            catch (e) {
                if (e instanceof Error) {
                    yield this.alert(e.message, "存入失敗", "error");
                }
                this.$router.back();
            }
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
__decorate([
    Prop()
], Page.prototype, "code", void 0);
__decorate([
    Prop()
], Page.prototype, "ticketId", void 0);
Page = __decorate([
    Component({ components: { TicketHeader, RangeDisplay } })
], Page);
export default Page;
