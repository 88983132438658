var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Scanner from "@/components/Scanner.vue";
import TicketModal from "../info/scan/TicketModal.vue";
let Page = class Page extends Vue {
    scan(code) {
        return __awaiter(this, void 0, void 0, function* () {
            const match = code.match(/(eg|pg)\/([0-9a-f]{32})$/);
            if (!match) {
                yield this.alert("票券格式不正確");
            }
            else {
                this.$router.push(`/reception/ticket/${this.id}/${match[1]}/${match[2]}`);
            }
        });
    }
    input() {
        return __awaiter(this, void 0, void 0, function* () {
            const code = yield this.$refs.add.show();
            if (code != null) {
                const url = yield post('/api/Info/QueryTicket/', { Id: code });
                if (url != null) {
                    yield this.scan(url);
                }
                else {
                    yield this.alert("券號無效");
                }
            }
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
Page = __decorate([
    Component({ components: { Scanner, TicketModal } })
], Page);
export default Page;
