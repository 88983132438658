var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import Outlet from "./components/Outlet.vue";
let Page = class Page extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.promotion = null;
        this.selectedPromotion = null;
        this.selectedItem = null;
        this.manualCount = null;
    }
    promoItem(p) {
        return `<div>${p.Name}</div><div class="small">${p.Remark || "&emsp;"}</div>`;
    }
    get title() {
        return this.codename == "eventgiveaway" ? "發送次數" : "發送集點";
    }
    get unit() {
        return this.codename == "eventgiveaway" ? "次" : "點";
    }
    get manual() {
        if (!this.promotion)
            return null;
        const item = this.promotion.Options[0];
        if (!item || item.Count !== null)
            return null;
        return item;
    }
    get ok() {
        if (!this.promotion)
            return false;
        if (this.manual)
            return Number(this.manualCount) > 0;
        else
            return this.selectedItem !== null;
    }
    /**
     * `null` 代表選取隨機型
     */
    get selectedCount() {
        if (this.manual)
            return this.manualCount;
        if (!this.selectedItem)
            return null;
        return this.selectedItem.MaxCount ? null : this.selectedItem.Count;
    }
    mounted() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.data = yield post("/api/Reception/Outlet", { Id: this.id, Name: this.codename });
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message);
                this.$router.back();
            }
        });
    }
    selectPromotion(p) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.selectedItem = null;
                this.selectedPromotion = p;
                this.promotion = null;
                this.promotion = yield post("/api/Reception/PromotionOptions", { Id: p.Id });
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message);
                this.selectedPromotion = null;
                this.promotion = null;
            }
        });
    }
    send() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.selectedPromotion)
                return;
            const result = yield post("/api/Reception/CreateRecord", {
                PromotionId: this.selectedPromotion.Id,
                OutletId: this.id,
                Count: this.selectedCount,
            });
            this.$router.push("/reception/send/" + result.Id);
        });
    }
};
__decorate([
    Inject()
], Page.prototype, "alert", void 0);
__decorate([
    Prop()
], Page.prototype, "id", void 0);
__decorate([
    Prop()
], Page.prototype, "codename", void 0);
Page = __decorate([
    Component({ components: { Outlet } })
], Page);
export default Page;
