
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import Outlet from "./components/Outlet.vue";
	import type { PromotionModel } from "@/models/Owner";

	import type { ReceptionOutletModel, DistributerOptionModel, DistributerOptionItem, DistributerRequestResult } from "@/models/Reception";

	@Component({ components: { Outlet } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id!: string;
		@Prop() public codename!: string;

		protected data: ReceptionOutletModel | null = null;
		protected promotion: DistributerOptionModel | null = null;

		protected selectedPromotion: PromotionModel | null = null;
		protected selectedItem: DistributerOptionItem | null = null;
		protected manualCount: number | null = null;

		protected promoItem(p: PromotionModel): string {
			return `<div>${p.Name}</div><div class="small">${p.Remark || "&emsp;"}</div>`;
		}

		get title(): string {
			return this.codename == "eventgiveaway" ? "發送次數" : "發送集點";
		}

		get unit(): string {
			return this.codename == "eventgiveaway" ? "次" : "點";
		}

		get manual(): DistributerOptionItem | null {
			if(!this.promotion) return null;
			const item = this.promotion.Options[0];
			if(!item || item.Count !== null) return null;
			return item;
		}

		get ok(): boolean {
			if(!this.promotion) return false;
			if(this.manual) return Number(this.manualCount) > 0;
			else return this.selectedItem !== null;
		}

		/**
		 * `null` 代表選取隨機型
		 */
		get selectedCount(): number | null {
			if(this.manual) return this.manualCount;
			if(!this.selectedItem) return null;
			return this.selectedItem.MaxCount ? null : this.selectedItem.Count;
		}

		mounted(): void {
			this.load();
		}

		private async load() {
			try {
				this.data = await post<ReceptionOutletModel>("/api/Reception/Outlet", { Id: this.id, Name: this.codename });
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message);
				this.$router.back();
			}
		}

		protected async selectPromotion(p: PromotionModel): Promise<void> {
			try {
				this.selectedItem = null;
				this.selectedPromotion = p;
				this.promotion = null;
				this.promotion = await post<DistributerOptionModel>("/api/Reception/PromotionOptions", { Id: p.Id });
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message);
				this.selectedPromotion = null;
				this.promotion = null;
			}
		}

		protected async send(): Promise<void> {
			if(!this.selectedPromotion) return;
			const result = await post<DistributerRequestResult>("/api/Reception/CreateRecord", {
				PromotionId: this.selectedPromotion.Id,
				OutletId: this.id,
				Count: this.selectedCount,
			});
			this.$router.push("/reception/send/" + result.Id);
		}
	}
